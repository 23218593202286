export default class Error {
  constructor (error) {
    this.error = error
  }

  isCarItineraryPickUpdateChangeForbidden () {
    return this.error.response.data.message === 'ORDER_PICK_UP_DATE_CHANGE_FORBIDDEN'
  }

  isCarItineraryUnloadedChangeForbidden () {
    return this.error.response.data.message === 'ORDER_UNLOADED_DATE_CHANGE_FORBIDDEN'
  }
}
