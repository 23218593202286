export default class CompartmentFilter {
  constructor () {
    this.parameters = []
  }

  getParameters () {
    return this.parameters
  }

  addParameter (parameter) {
    this.parameters.push(parameter)
  }
}
