export default class CarItineraryFilter {
  constructor () {
    this.columns = []
    this.parameters = []
  }

  getColumns () {
    return this.columns
  }

  addColumn (column) {
    this.columns.push(column)
  }

  getParameters () {
    return this.parameters
  }

  addParameter (parameter) {
    this.parameters.push(parameter)
  }
}
