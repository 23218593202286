import Locale from '@/libraries/Util/Locale'
import String from '@/libraries/Util/String'

export default class Integer {
  static thousandFormat (number) {
    number = Math.trunc(number)

    const thousandSeparator = Locale.thousandSeparator()

    return String.replace(thousandSeparator, ' ', number.toLocaleString())
  }
}
