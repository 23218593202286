import Locale from '@/libraries/Util/Locale'
import String from '@/libraries/Util/String'

export default class Float {
  static thousandFormat (number) {
    const thousandSeparator = Locale.thousandSeparator()

    return String.replace(thousandSeparator, ' ', number.toLocaleString())
  }

  static trimZeroes (number) {
    return parseFloat(number)
  }
}
