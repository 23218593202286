export default class Locale {
  static thousandSeparator () {
    if (typeof Number.prototype.toLocaleString === 'function') {
      const num = 1000
      const numStr = num.toLocaleString()
      if (numStr.length === 5) {
        return numStr.substr(1, 1)
      }
    }
    return ','
  }
}
