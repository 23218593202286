import moment from 'moment'
import store from '@/store'
import md5 from 'js-md5'

export default class CarItinerary {
  constructor (carItinerary = null) {
    this.carItinerary = carItinerary
    this.lodash = require('lodash')
  }

  isPickedUp () {
    if (this.lodash.get(this.carItinerary, 'pickUpDate') !== undefined) {
      return moment(this.carItinerary.pickUpDate).isValid()
    }

    return false
  }

  isUnloaded () {
    if (this.lodash.get(this.carItinerary, 'unloadedDate') !== undefined) {
      return moment(this.carItinerary.unloadedDate).isValid()
    }

    return false
  }

  unloadedDate () {
    if (this.lodash.get(this.carItinerary, 'unloadedDate') !== undefined) {
      return this.carItinerary.unloadedDate
    }

    return null
  }

  getTitle () {
    return this.getFlightNumber() + ' ' + this.getOriginIata() + '-' + this.getDestinationIata()
  }

  hasConsigneeSignature () {
    return (this.lodash.get(this.carItinerary, 'consigneeSignature') !== undefined && this.lodash.get(this.carItinerary, 'consigneeSignature') !== null)
  }

  getCarItinerary () {
    return this.carItinerary
  }

  getCarId () {
    if (this.lodash.get(this.carItinerary, 'car.id') !== undefined) {
      return this.carItinerary.car.id
    }

    return null
  }

  getClient () {
    if (this.lodash.get(this.carItinerary, 'car.payer.company') !== undefined) {
      return this.carItinerary.car.payer.company
    }

    return ''
  }

  getState () {
    if (this.lodash.get(this.carItinerary, 'state') !== undefined) {
      return this.carItinerary.state
    }

    return 'initial'
  }

  getFlightNumber () {
    if (this.lodash.get(this.carItinerary, 'car.ccfn') !== undefined && this.lodash.get(this.carItinerary, 'car.std') !== undefined) {
      return this.carItinerary.car.ccfn + '/' + moment(this.carItinerary.car.std).format('DDMMMYY').toUpperCase()
    }

    return ''
  }

  getTruckNumber () {
    let truckNumber = ''
    if (this.lodash.get(this.carItinerary, 'truck.registrationNumber') !== undefined) {
      truckNumber += this.carItinerary.truck.registrationNumber
    }

    truckNumber += '/'

    if (this.lodash.get(this.carItinerary, 'trailer.registrationNumber') !== undefined) {
      truckNumber += this.carItinerary.trailer.registrationNumber
    }

    return truckNumber
  }

  getOriginIata () {
    if (this.lodash.get(this.carItinerary, 'originAirport.code') !== undefined) {
      return this.carItinerary.originAirport.code
    }

    return ''
  }

  getDestinationIata () {
    if (this.lodash.get(this.carItinerary, 'destinationAirport.code') !== undefined) {
      return this.carItinerary.destinationAirport.code
    }

    return ''
  }

  getPieces () {
    if (this.lodash.get(this.carItinerary, 'car.totals.piecesManifested') !== undefined) {
      return this.carItinerary.car.totals.piecesManifested
    }

    return 0
  }

  getKilograms () {
    if (this.lodash.get(this.carItinerary, 'car.totals.weightManifested') !== undefined) {
      return this.carItinerary.car.totals.weightManifested
    }

    return 0
  }

  getVolume () {
    if (this.lodash.get(this.carItinerary, 'car.totals.volumeManifested') !== undefined) {
      return parseFloat(this.carItinerary.car.totals.volumeManifested).toFixed(1)
    }

    return 0
  }

  getPickUpDate () {
    if (this.lodash.get(this.carItinerary, 'pickUpDate') !== undefined) {
      return this.carItinerary.pickUpDate
    }

    return null
  }

  getGroups () {
    const groups = []
    const _self = this
    if (this.lodash.get(_self.carItinerary, 'car.shipments') !== undefined) {
      _self.carItinerary.car.shipments.forEach(function (shipment) {
        if (_self.lodash.get(shipment, 'shcs') !== undefined) {
          shipment.shcs.forEach(function (shc) {
            if (_self.lodash.get(shc, 'groups') !== undefined && _self.lodash.get(shc, 'showInQDrive') !== undefined && _self.lodash.get(shc, 'showInQDrive')) {
              shc.groups.forEach(function (group) {
                if (_self.lodash.get(group, 'name') !== undefined) {
                  groups.push({ id: group.id, name: group.name, slug: group.slug })
                }
              })
            }
          })
        }
      })
    }

    return this.lodash.uniqBy(groups, function (group) {
      return group.id
    })
  }

  getNotice () {
    if (this.lodash.get(this.carItinerary, 'car.notice') !== undefined) {
      return this.carItinerary.car.notice
    }

    return null
  }

  isNoticeRead (accessType) {
    const _self = this
    let isRead = false
    if (this.lodash.get(_self.carItinerary, 'noticeViews') !== undefined && _self.carItinerary.car.notice !== undefined) {
      const messageSignature = md5(_self.carItinerary.car.notice)
      _self.carItinerary.noticeViews.forEach(function (noticeView) {
        if (accessType === 'private') {
          if (_self.lodash.get(noticeView, 'admin.name') !== undefined) {
            if (noticeView.admin.name === store.getters.admin.name && noticeView.signature === messageSignature) {
              isRead = true
            }
          }
        } else if (accessType === 'public') {
          if (_self.lodash.get(noticeView, 'id') !== undefined && _self.lodash.get(noticeView, 'admin') === null) {
            isRead = true
          }
        }
      })
    }
    return isRead
  }
}
