export default class Parameter {
  constructor (name, value) {
    this.name = name
    this.value = value
  }

  getName () {
    return this.name
  }

  getValue () {
    return this.value
  }
}
