export default class Utilities {
  static getToSeal (instance) {
    if (instance.isPrivate()) {
      instance.$router.replace({ name: 'CarItinerarySeals' })
    } else {
      instance.$router.replace({ name: 'EncryptedCarItinerarySeals' })
    }
  }

  static redirectToPage (instance, page) {
    if (instance.isPrivate()) {
      instance.$router.push({ name: 'CarItinerary' + page }).catch(() => {})
    } else {
      instance.$router.push({ name: 'EncryptedCarItinerary' + page }).catch(() => {})
    }
  }

  static selectedButton (instance, route, filter) {
    if (instance.isPrivate()) {
      return instance.breadcrumb[2].route !== route
    } else {
      if (instance.breadcrumb[2].route === 'encrypted' && route === filter) {
        return false
      }
      return instance.breadcrumb[2].route !== 'encrypted' + route
    }
  }

  static compartmentStatus (compartments) {
    let allowLoading = false
    let brokenSeal = false
    compartments.forEach(function callback (compartment) {
      if (compartment.seals.length === 0) {
        allowLoading = true
      }
      compartment.seals.forEach(function callback (seal) {
        if (seal.removed_at !== null && compartment.sealed_at !== null) {
          allowLoading = true
          brokenSeal = true
        }
        if (seal.removed_at === null && compartment.sealed_at === null) {
          allowLoading = true
        }
      })
    })

    const countCompartment = compartments.map(item => item.seals.length).reduce((a, b) => a + b, 0)

    if (countCompartment === 0) {
      allowLoading = true
    }

    const emptyCompartment = compartments.filter(item => item.sealed_at && item.seals.length === 0).length
    const emptyBrokenCompartment = compartments.filter(item => !item.sealed_at).length

    if (emptyCompartment && emptyBrokenCompartment === 0 && !brokenSeal) {
      allowLoading = false
    }

    return allowLoading
  }
}
